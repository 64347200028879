import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/scan-with-trivy",
  "date": "15th March 2023",
  "title": "Boost your security with Skpr image scanning",
  "summary": "Getting the most out of your security pipelines using security scanning",
  "author": "Karl Hepworth",
  "tag": "Security",
  "tagColor": "yellow",
  "tags": [{
    "name": "Security scanning"
  }, {
    "name": "Continuous integration"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Introducing Trivy to Skpr`}</h2>
    <p>{`Trivy is now available through the official Skpr CLI Docker image!`}</p>
    <p>{`Trivy is an open-source security scanning tool maintained and developed primarily by
`}<a parentName="p" {...{
        "href": "https://www.aquasec.com/"
      }}>{`Acqua Security`}</a>{` - a key player in the Cloud Native ecosystem.`}</p>
    <p>{`It allows users to scan dependency files, file systems, docker images, and more against their frequently updated
vulnerability database.`}</p>
    <h2>{`How are we making security easier?`}</h2>
    <p>{`Skpr users can now automatically scan their images and determine if their application has any immediate vulnerabilities.
Our CLI image comes with Trivy, so you can easily integrate it with your workflow.`}</p>
    <p>{`Available now in `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/integrations/circleci/skpr/"
      }}>{`Skpr Orb`}</a>{`, taking advantage of our CircleCI
integration requires minimal effort.`}</p>
    <p>{`This solution is not limited to Trivy. We're able to integrate many other tools, so let us know if another scanning tool
fits your workflow.`}</p>
    <p>{`Updates to the Orb will help you to:`}</p>
    <ul>
      <li parentName="ul">{`Scan dependency files for vulnerabilities in your supply chain.`}</li>
      <li parentName="ul">{`Scan the Docker images that run your applications for vulnerabilities.`}</li>
      <li parentName="ul">{`Run security scans on projects before they deploy to production.`}</li>
      <li parentName="ul">{`Scan at any time, including on a schedule.`}</li>
      <li parentName="ul">{`Be more proactive, and create tickets for issues of concern when they emerge (and before they surprise you).`}</li>
    </ul>
    <p>{`Trivy is fast and effective. It can be made available on each release or pull request. It's flexible to your needs and
can be adjusted in your workflow's configuration. CI logs will show the scan outcomes in CircleCI, allowing you to take
security considerations into account during the development cycle.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20230315/diagram.jpg",
        "alt": "Image of development workflow with a scanning and verification step"
      }}></img></p>
    <h2>{`What do I need to do?`}</h2>
    <p>{`If you're interested in upping your security when developing a new feature or running routine scans, look at our
`}<a parentName="p" {...{
        "href": "https://docs.skpr.io/integrations/circleci/trivy/"
      }}>{`documentation`}</a>{` covering implementation examples using the Skpr Orb.`}</p>
    <p>{`If you have any questions or would like a demo or advice on integrating Trivy into your workflow,
`}<a parentName="p" {...{
        "href": "#request-a-demo"
      }}>{`please contact the Skpr team`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      